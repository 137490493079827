import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SharedService } from './shared.Service';
import { AuthService } from '../auth/auth.service';
import { PermissionService } from './permission/permission.service';
import { AppService } from './session/AppService';

@Injectable()
export class RoutingService {
    public role: any;
    sideMenus: Array<any>;
    constructor(private authService: AuthService, private permissionService: PermissionService,
        private sharedService: SharedService, private appService: AppService) {
        this.sideMenus = [];
        this.role = this.sharedService.userRole;
        if (!this.sharedService.userRole) {
            this.logout();
        }
        if (this.sharedService.permissionList.length === 0) {
            this.logout();
        }
    }

    navigateTo(event: NavigationStart): string {
        let url = event.url;
        this.role = this.sharedService.userRole;
        const role = this.role.toLowerCase();
        switch (role) {
            case 'admin':
            case 'super admin':
                if (url === '/' || url === '/pages/login') {
                    url = this.defaultRoutebyRole(this.role);
                } else if (url === '/') {
                    url = this.defaultRoutebyRole(this.role);
                } else if (url === '/logout') {
                    this.logout();
                    url = '/';
                } else {
                    url = this.checkPermission(url) ? url : '/pages/unauthorized';
                }
                break;
            default:
                if (url === '/pages/login') {
                    url = this.defaultRoutebyRole(this.role);
                } else if (url === '/' || url === '/pages/unauthorized') {
                    url = this.defaultRoutebyRole(this.role);
                } else if (url === '/logout') {
                    this.logout();
                    url = '/';
                } else {
                    url = this.checkPermission(url) ? url : '/pages/unauthorized';
                }
                break;
        }
        return url.toLowerCase();
    }

    // routes to landing-page before login if user is try to access authorized pages
    private defaultRouteTo(url: string): string {
        switch (url) {
            case '/':
            case '/pages/unauthorized':
                break;
            default: url = '/pages/login';
                break;
            // default: url = this.defaultRoutebyRole(this.role);
            //     break;
        }
        return url;
    }

    defaultRoutebyRole(role: string): string {
        this.sharedService.userRole = this.role = (role || this.role);
        if (this.sideMenus.length === 0 && this.sharedService.userRole) {
            this.sideMenus = this.permissionService.getNavigationMenus(this.sharedService.permissionList);
            this.sharedService.sideMenus = this.sideMenus;
        }
        let url;
        if (this.sharedService.userRole === 'DesignApprover' || this.sharedService.userRole === 'Designer') {
            url = '/designtaskboard';
        } else {
            url = this.sideMenus.length > 0 ? this.sideMenus[0].path : '/pages/login';
        }
        return url.toLowerCase();
    }

    checkPermission(url: string): boolean {
        let hasPermission = false;
        if (this.sideMenus.length === 0) {
            this.sideMenus = this.permissionService.getNavigationMenus(this.sharedService.permissionList);
        }
        this.sideMenus.forEach((menu) => {
            if (menu.path === null && menu.submenu.length !== 0) {
                for (let i = 0; i < menu.submenu.length; i += 1) {
                    if (url.toLowerCase().indexOf(menu.submenu[i].path) > -1) {
                        hasPermission = true;
                        return hasPermission;
                    }
                }
            } else {
                if (url.toLowerCase().indexOf(menu.path) > -1) {
                    hasPermission = true;
                    return hasPermission;
                }
            }
        })
        return hasPermission;
    }

    logout() {
        this.role = '';
        this.sideMenus = [];
        this.appService.setUserLoggedIn(false);
        this.appService.setuserSessionTime(false);
        this.authService.logout();
    }
}
