import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Roles } from 'app/shared/models/roles/roles.model';
import { Response } from 'app/shared/models/response/response.model';
import { SharedService } from '../shared.Service';


@Injectable()
export class RolesService {
    static GET_PATH = `${environment.apiPath}` + 'getrolesbysearchcriteria';
    static GET_PATH1 = `${environment.apiPath}` + 'getuserroles';
    static SAVE_PATH = `${environment.apiPath}` + 'addrole';
    static UPDATE_PATH = `${environment.apiPath}` + 'updaterole';
    static DELETE_PATH = `${environment.apiPath}` + 'deleterole';
    constructor(private apiService: ApiService, private sharedService: SharedService) { }

    searchRole(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.roleName, IsActive: searchObj.isActive
            };
            this.apiService.postData(RolesService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateRolesList(result.roles), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Status || error.status], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateRolesList(roles: any): any {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            const role = {
                sNo: index + 1,
                id: roles[index].id,
                roleName: roles[index].name,
                isActive: roles[index].isActive ? 'Yes' : 'No',
            };
            roleList.push(role);
        }
        return roleList;
    }
    getUserRoleByUserId(role: any): Observable<any> {
        const observable = new Observable((observer) => {
            let apiUrl = RolesService.GET_PATH1;
            apiUrl = apiUrl + '/' + role;
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUsersList(result.userRoles), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error: error.error.Message, success: false
                };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateUsersList(roles: any): any {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            const role = {
                id: roles[index].roleId,
                name: roles[index].roleName,
            };
            roleList.push(role);
        }
        return roleList;
    }
    saveRole(roles: Roles): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(RolesService.SAVE_PATH, roles).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.message };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateRole(roles: Roles): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(RolesService.UPDATE_PATH, roles).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: roles.Name + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteRole(roleId: number, isActiveStatus: boolean, roleName: string): Observable<any> {
        let apiUrl = RolesService.DELETE_PATH;
        apiUrl = apiUrl + '/' + roleId + '/' + (!isActiveStatus);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: roleName + (!isActiveStatus
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
}