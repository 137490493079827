import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from 'app/shared/models/response/response.model';
import { ForgotPassword, ResetPasswordRequest, UserRequest } from 'app/shared/models/user/user.model';
import { SharedService } from '../shared.Service';
@Injectable()
export class UserService {
    static SAVE_PATH = `${environment.apiPath}` + 'register';
    static SAVE_PATH1 = `${environment.apiPath}` + 'forgot-password';
    static SAVE_PATH2 = `${environment.apiPath}` + 'setpassword';
    static UPDATE_PATH = `${environment.apiPath}` + 'updateuser';
    static GET_PATH = `${environment.apiPath}` + 'getallusers';
    static GET_PATH1 = `${environment.apiPath}` + 'getusersbyrole';
    static GET_PATH2 = `${environment.apiPath}` + '';
    static UPDATE_PATH1 = `${environment.apiPath}` + 'useradress';
    static GET_PATH3 = `${environment.apiPath}` + 'getaddresstypes';
    static GET_PATH4 = `${environment.apiPath}` + 'getuserdetails';
    static GET_PATH5 = `${environment.apiPath}` + '';
    static GET_PATH6 = `${environment.apiPath}` + '';
    static GET_PATH7 = `${environment.apiPath}` + 'userdetails';
    static DELETE_PATH = `${environment.apiPath}` + 'deleteuser';
    static CHANGE_PATH = `${environment.apiPath}` + 'change-password';
    constructor(private apiService: ApiService, private sharedService: SharedService) { }

    register(user: UserRequest): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(UserService.SAVE_PATH, user).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.status) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    updateUser(user: UserRequest): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(UserService.UPDATE_PATH, user).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.stringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.status) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    forgotPassword(email: ForgotPassword): Observable<any> {
        const request = {
            Email: email.EMail
        };
        const observable = new Observable((observer) => {
            this.apiService.postData(UserService.SAVE_PATH1, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.status) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    ResetPassword(request: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(UserService.SAVE_PATH2, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error) {
                    res.error = error.error.text;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    saveAddress(address: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(UserService.UPDATE_PATH1, address).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true, result: result.message
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.detail) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }


    keyFormate(key: string): string {
        if (key.indexOf('_') === -1) {
            return key[0].toUpperCase() + key.slice(1);
        } else {
            return key;
        }
    }

    setUID(pwd: string): string {
        return btoa(pwd);
    }

    getUserList(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                UserName: searchObj.userName, IsActive: searchObj.status,
            };
            this.apiService.postData(UserService.GET_PATH4, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                            users: this.populateUserData(result.userResponses),
                            total: result.TotalCount
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error: error.error.detail, success: false
                };
                observer.next(response);
            });
        });
        return observable;
    }
    getAddressTypes(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(UserService.GET_PATH3).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    observer.next(result);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getAddressDetails(partyId: number, type: number): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                UserId: partyId, AddressType: type
            };
            this.apiService.postData(UserService.GET_PATH5, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAddress(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error: error.error.Message, success: false
                };
                observer.next(response);
            });
        });
        return observable;
    }
    getUsersByRole(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(UserService.GET_PATH1).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUsers(result.usersByRoles), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error:
                        error.error.detail, success: false
                };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateUsers(users: any): any {
        const roleList = [];
        for (let index = 0; index < users.length; index++) {
            const role = {
                id: users[index].id,
                name: users[index].name,
            };
            roleList.push(role);
        }
        return roleList;
    }
    getAllUsers(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(UserService.GET_PATH4).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                            users: this.populateUserDatails(result.UserInfos),
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: error.error.detail, success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getAllCustomers(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(UserService.GET_PATH6).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUserDatails(result.UserInfos), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: error.error.detail, success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    changePassword(changePassword: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(UserService.CHANGE_PATH, changePassword).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true, result: result.message
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.detail) {
                    res.error = error.error.detail;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    private populateAddress(result: any): any {
        const address = {
            name: result.Name,
            companyName: result.CompanyName,
            address1: result.Address1,
            address2: result.Address2,
            stateId: result.StateId,
            city: result.City,
            stateGstCode: result.StateGstCode,
            stateName: result.StateName,
            country: result.Country,
            landMark: result.LandMark,
            postalCode: result.PostalCode
        };
        return address
    }
    private populateUserDatails(users: Array<any>): Array<any> {
        const userData = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                id: users[index].Id,
                userName: users[index].UserName,
                fullName: users[index].FullName,
                name: users[index].Name,
                companyName: users[index].CompanyName,
                address1: users[index].Address1,
                // address1 : users[index].Address1 + ',' + '\n'  +  users[index].LandMark +  ',' +
                // users[index].City + ',' + '\n'  + users[index].PostalCode + '.' ,
                address2: users[index].Address2,
                stateId: users[index].StateId,
                city: users[index].City,
                stateGstCode: users[index].StateGstCode,
                stateName: users[index].StateName,
                country: users[index].Country,
                landMark: users[index].LandMark,
                postalCode: users[index].PostalCode
            };
            userData.push(user);
        }
        return userData;
    }
    private populateCustomers(users: Array<any>): Array<any> {
        const userData = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                id: users[index].Id,
                name: users[index].Name
            };
            userData.push(user);
        }
        return userData;
    }
    private populateUserData(users: Array<any>): Array<any> {
        const userData = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                sNo: index + 1,
                id: users[index].id,
                userName: users[index].userName,
                fullName: users[index].fullName,
                firstName: users[index].firstName,
                lastName: users[index].lastName,
                storeName: users[index].storeName,
                store: users[index].storeId,
                role: users[index].roleId,
                roleName: users[index].roleName,
                email: users[index].email,
                mobileNo: users[index].phoneNumber,
                isActive: users[index].isActive ? 'Yes' : 'No',
                userAddress: this.populateUserAddressList(users[index].userAddressDetails || [])
            };
            userData.push(user);
        }
        return userData;
    }
    private populateUserAddressList(address) {
        const addressList = [];
        for (let index = 0; index < address.length; index++) {
            const value = {
                Sno: index + 1,
                AddressId: address[index].addressId,
                AddressType: address[index].addressType,
                AddressTypeName: address[index].addressTypeName,
                Name: address[index].name,
                StateProvinceId: address[index].stateProvinceId,
                Country: address[index].country,
                State: address[index].state,
                Address1: address[index].address1,
                Address2: address[index].address2,
                City: address[index].city,
                LandMark: address[index].landMark,
                PostalCode: address[index].postalCode,
                FaxNumber: address[index].faxNumber,
                IsActive: true
            };
            addressList.unshift(value);
        }
        return addressList;
    }
    deleteUserDetails(userName: string, id: number, status: boolean): Observable<any> {
        let apiUrl = UserService.DELETE_PATH;
        let modifiedBy = this.sharedService.userFullName;
        apiUrl = apiUrl + '/' + id + '/' + (!status) + '/' + modifiedBy;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ?
                            result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: userName + (!status
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    searchUserName(userName: string): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                UserName: userName,
            };
            this.apiService.postData(UserService.GET_PATH2, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: error.error.Message, success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    getRolesBySearchCriteria(searchObj: any) {
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.Name, IsActive: searchObj.IsActive
            };
            this.apiService.postData(UserService.GET_PATH1, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.buildResponseModel(result.Roles), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = error.error.detail;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getUserDetailsByUserId(): Observable<any> {
        const observable = new Observable((observer) => {
            let apiUrl = UserService.GET_PATH7;
            apiUrl = apiUrl + '/' + this.sharedService.appUserId;
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: result
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: error.error.detail, success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private buildResponseModel(roles: Array<any>) {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            roleList.push({
                id: roles[index].Id,
                name: roles[index].Name,
                isActive: roles[index].IsActive
            });
        }
        return roleList;
    }
}
