import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from '../services/api.service';
import { SharedService } from '../services/shared.Service';
import { Response } from '../models/response/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  static AUTH_PATH = `${environment.apiPath}` + 'authenticate';
  static Refresh_PATH = `${environment.apiPath}` + 'refresh';
  static Revoke_PATH = `${environment.apiPath}` + 'revoke-token';

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenTimeout;
  constructor(private apiService: ApiService, private sharedService: SharedService, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(userName: string, password: string) {
    const observable = new Observable((observer) => {
      const request = {
        Email: userName,
        Password: password
      };
      this.apiService.postData(AuthService.AUTH_PATH, request).subscribe((response: any) => {
        if (response instanceof HttpErrorResponse) {
          const res: Response = {
            failure: true, success: false,
            error: response.status === 400 ? response.error : environment.errorMessages[response.status]
          };
          observer.next(res);
        } else {
          const res: Response = {
            failure: false, success: true,
            error: null, result: response
          };
          this.sharedService.clear();
          this.sharedService.token = response.data.accessToken;
          this.sharedService.refreshToken = response.data.refreshToken;
          this.sharedService.tokenExpireTime = response.data.tokenExpiry;
          this.sharedService.appUserId = response.data.id;
          this.sharedService.email = response.data.email;
          this.sharedService.userFullName = response.data.firstName + response.data.lastName;
          this.sharedService.userName = response.data.userName;
          this.refreshTokenSubject.next(response);
          this.startRefreshTokenTimer();
          observer.next(res);
        }
      }, (err: HttpErrorResponse) => {
        const res: Response = {
          failure: true, success: false,
          error: ''
        };
        if (err.error && err.error.status) {
          res.error = err.error.detail;
        } else {
          res.error = environment.errorMessages[err.status]
        }
        observer.next(res);
      });
    });
    return observable;
  }

  logout() {
    this.stopRefreshTokenTimer();
    if (this.sharedService.userRole) {
      this.revokeToken().subscribe(() => {
        this.refreshTokenSubject.next(null);
        this.sharedService.clear();
        this.router.navigate(['/logout'], { relativeTo: this.activatedRoute });
      }, (err: HttpErrorResponse) => {
        this.refreshTokenSubject.next(null);
        this.sharedService.clear();
        this.router.navigate(['/logout'], { relativeTo: this.activatedRoute });
        console.log(`Error occured while revoking token with error message ${err.message}`);
      });
    }
  }

  getToken() {
    return this.sharedService.token;
  }
  getRefreshToken() {
    const request = {
      accessToken: this.sharedService.token,
      refreshToken: this.sharedService.refreshToken
    }
    const observable = new Observable((observer) => {
      this.apiService.postData(AuthService.Refresh_PATH, request).subscribe((result) => {
        if (result instanceof HttpErrorResponse) {
          const res: Response = {
            failure: true, success: false,
            error: result.status === 400 ? result.error : environment.errorMessages[result.status]
          };
          observer.next(res);
        } else {
          const res: Response = {
            failure: false, success: true,
            result: result, error: null,
          };
          this.sharedService.token = res.result.accessToken;
          this.sharedService.refreshToken = res.result.refreshToken;
          this.sharedService.tokenExpireTime = res.result.tokenExpiry;
          this.refreshTokenSubject.next(res.result);
          this.startRefreshTokenTimer();
          observer.next(res);
        }
      }, (error) => {
        const response: Response = { failure: true, error: error.error.detail, success: false };
        observer.next(response);
      });
    });
    return observable;
  }
  revokeToken() {
    const RevokeTokenRequest = {
      Token: this.sharedService.refreshToken,
    }
    const observable = new Observable((observer) => {
      this.apiService.postData(AuthService.Revoke_PATH, RevokeTokenRequest).subscribe((result) => {
        if (result instanceof HttpErrorResponse) {
          const res: Response = {
            failure: true, success: false,
            error: result.status === 400 ? result.error : environment.errorMessages[result.status]
          };
          observer.next(res);
        } else {
          const res: Response = {
            failure: false, success: true,
            result: result, error: null,
          };
          observer.next(res);
        }
      }, (error) => {
        const response: Response = { failure: true, error: error.error.detail, success: false };
        observer.next(response);
      });
    });
    return observable;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return !!this.sharedService.token;
  }

  public startRefreshTokenTimer() {
    if (this.sharedService.token) {
      // // parse json object from base64 encoded jwt token
      // const jwtToken = JSON.parse(atob(this.sharedService.token.split('.')[1]));
      // // set a timeout to refresh the token a minute before it expires
      // const expires = new Date(jwtToken.exp * 1000);
      // const timeout = expires.getTime() - new Date().getTime() - (60 * 1000);

      const expires = +this.sharedService.tokenExpireTime - 1;
      const timeout = (expires * 60 * 1000);
      this.stopRefreshTokenTimer();
      this.refreshTokenTimeout = timer(timeout).pipe(switchMap(() => this.getRefreshToken())).subscribe();
    }
  }

  private stopRefreshTokenTimer() {
    if (!this.refreshTokenTimeout) return;
    this.refreshTokenTimeout.unsubscribe();
  }
}
